import { FC, forwardRef, ReactNode } from 'react'
import clsx from 'clsx'

import s from './styles.module.scss'

type Props<T = Record<string, any>> = T & {
  children?: ReactNode
  className?: string
}

export const StageSection = forwardRef<HTMLDivElement, Props & { withCustomHeight?: boolean }>(({ className, children, withCustomHeight = false }, ref) => (
  <section ref={ref} className={clsx(s.root, className, { [s.customHeight]: withCustomHeight })}>
    {children}
  </section>
))

export const ForegroundLayerContainer = forwardRef<HTMLDivElement, Props>(({ className, children }, ref) => (
  <div ref={ref} className={clsx(s.foregroundLayerContainer, className)}>
    {children}
  </div>
))

export const ForegroundLayer: FC<Props> = forwardRef<HTMLDivElement, Props>(({ className, children }, ref) => (
  <div ref={ref} className={clsx(s.foregroundLayer, className)}>
    {children}
  </div>
))

export const Title: FC<Props> = forwardRef<HTMLParagraphElement, Props>(({ className, children }, ref) => (
  <p ref={ref} className={clsx(s.title, className)}>
    {children}
  </p>
))

export const Subtitle: FC<Props> = forwardRef<HTMLParagraphElement, Props>(({ className, children }, ref) => (
  <p ref={ref} className={clsx(s.subtitle, className)}>
    {children}
  </p>
))

export const BackgroundLayer = forwardRef<HTMLDivElement, Props>(({ className, children }, ref) => (
  <div ref={ref} className={clsx(s.backgroundLayer, className)}>
    {children}
  </div>
))

export const StageWrapper = forwardRef<HTMLDivElement, Props>(({ className, children }, ref) => (
  <div ref={ref} className={clsx(s.stageWrapper, className)}>
    {children}
  </div>
))

export const Stage = forwardRef<HTMLDivElement, Omit<Props, 'children'>>(({ className }, ref) => <div ref={ref} className={clsx(s.stage, className)} />)

export const BoxTopImage = forwardRef<HTMLDivElement, Omit<Props, 'children'>>(({ className }, ref) => (
  <div ref={ref} className={clsx(s.boxTopImage, className)} aria-hidden={true} />
))

export const Overlay = forwardRef<HTMLDivElement, Omit<Props, 'children'>>(({ className }, ref) => <div ref={ref} className={clsx(s.overlay, className)} />)
