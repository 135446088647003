import { createContext, Dispatch, FC, SetStateAction, useState } from 'react'

type ContextType = {
  width: number
  // ReturnType<typeof useProgress>
  loadingProgress: null | {
    errors: string[]
    active: boolean
    progress: number
    item: string
    loaded: number
    total: number
  }
}
type BackgroundContextType = [ContextType, Dispatch<SetStateAction<ContextType>>]

export const backgroundContext = createContext<null | BackgroundContextType>(null)

export const BackgroundContextProvider: FC = ({ children }) => {
  const [contextValue, setContextValue] = useState<ContextType>({
    width: 0,
    loadingProgress: null,
  })

  const { Provider } = backgroundContext

  return <Provider value={[contextValue, setContextValue]}>{children}</Provider>
}
