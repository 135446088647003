import { FC } from 'react'

import { ReactComponent as IconVideo } from './IconVideo.svg'
import { ReactComponent as IconJest } from './IconJest.svg'
import { ReactComponent as IconJoystick } from './IconJoystick.svg'
import { ReactComponent as IconPayments } from './IconPayments.svg'

import s from './styles.module.scss'

export const CameraBenefits: FC = () => (
  <section className={s.root}>
    <div className={s.spacerTop} />
    <div className={s.container}>
      <div className={s.title}>
        <p className={s.titleText}>Умная камера</p>
      </div>
      <div className={s.subtitle}>
        <div className={s.subtitleText}>
          <p className={s.subtitleTextValue}>Широкий угол обзора и&nbsp;умный фокус</p>
        </div>
        <span className={s.subtitleAngle}>
          <span className={s.subtitleAngleValue}>120&deg;</span>
        </span>
      </div>

      <ul className={s.benefits}>
        <li className={s.benefit}>
          <IconVideo className={s.benefitIcon} />
          <span className={s.benefitHeader}>Для видеозвонков</span>
          Оставайтесь в&nbsp;кадре, даже когда перемещаетесь по&nbsp;комнате
        </li>
        <li className={s.benefit}>
          <IconJest className={s.benefitIcon} />
          <span className={s.benefitHeader}>Для управления жестами</span>
          Останавливайте и&nbsp;возобновляйте воспроизведение жестами
        </li>
      </ul>

      <ul className={s.benefits}>
        <li className={s.benefit}>
          <IconJoystick className={s.benefitIcon} />
          <span className={s.benefitHeader}>Для игр и&nbsp;спорта</span>
          Запускайте игры и&nbsp;спортивные приложения с&nbsp;жестовым управлением и&nbsp;AR-эффектами&sup1;
        </li>
        <li className={s.benefit}>
          <IconPayments className={s.benefitIcon} />
          <span className={s.benefitHeader}>Безопасная оплата в&nbsp;два счёта</span>
          Персонализируйте доступ к&nbsp;сохранённой карте, оплачивайте подписки на&nbsp;сервисы при помощи технологий распознавания голоса и&nbsp;лица
        </li>
      </ul>
    </div>
    <div className={s.spacerBottom} />
  </section>
)
