import gsap from 'gsap'

import CustomEase from '_src/external/CustomEase.min.js'

gsap.registerPlugin(CustomEase)

export const PATHS = {
  MODEL: '/scene-transformed.glb',
  LENS_TEXTURE: '/camera_lens_low_weight.jpg',
  ENVIRONMENT: './studio_small_06_1k.hdr',
}

export const CUSTOM_EASING_STOP = CustomEase.create('custom', 'M0,0 C0.052,0.508 0.156,0.483 0.5,0.5 0.922,0.52 0.948,0.6 1,1 ')
export const CUSTOM_EASING_RS_IN = CustomEase.create('custom', 'M0,0 C0,0.5 -0.016,0.616 0.092,0.758 0.234,0.942 0.482,1 1,1 ')

export const DAMP_LAMBDA = 1.5
export const DAMP_TIME = 0.05

export const MODEL_MOVEMENT_DAMP_LAMBDA = 0.5
export const MODEL_MOVEMENT_DAMP_TIME = 1
