import { VFC } from 'react'
import { ScrollTrigger, Timeline, Tween } from 'react-gsap'

import { root as privacyBenefitsRoot } from '_components/sections/PrivacyBenefit/styles.module.scss'

import { ReactComponent as HandImage } from '../../hand.svg'
import s from '../../styles.module.scss'

export const Hand: VFC = () => {
  return (
    <ScrollTrigger trigger={`.${privacyBenefitsRoot}`} scrub={0.25} markers={false}>
      <Timeline
        target={
          <div
            className={s.handWrap}
            style={{
              transform: 'translate(100%, -100%)',
              opacity: '0',
            }}
          >
            <HandImage className={s.hand} aria-hidden={true} />
          </div>
        }
      >
        <Tween
          immediateRender={false}
          position={0}
          duration={0.5}
          ease={'power1.out'}
          from={{
            opacity: 0,
            x: '100%',
            y: '-100%',
          }}
          to={{
            opacity: 1,
            x: '0',
            y: '-60%',
          }}
        />
        <Tween
          immediateRender={false}
          position={0.5}
          duration={0.5}
          ease={'power1.in'}
          from={{
            x: '0',
            y: '-60%',
          }}
          to={{
            x: '100%',
            y: '-60%',
          }}
        />
        <Tween
          immediateRender={false}
          position={0.5}
          duration={1}
          ease={'power3.out'}
          from={{
            opacity: 1,
          }}
          to={{
            opacity: 0,
          }}
        />
      </Timeline>
    </ScrollTrigger>
  )
}
