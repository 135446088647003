import { FC } from 'react'
import { PlayState, Tween } from 'react-gsap'

import { useLoaderContext } from '_hooks'

import s from './styles.module.scss'

export const Header: FC = () => {
  const [{ done }] = useLoaderContext()

  return (
    <Tween from={{ y: '-100%' }} duration={2} delay={4} playState={done ? PlayState.play : PlayState.pause}>
      <header className={s.root} />
    </Tween>
  )
}
