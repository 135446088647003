import { FC, VFC, useRef } from 'react'
import { ScrollTrigger, Tween } from 'react-gsap'
import clsx from 'clsx'

import { useRayClipPath } from '_hooks'

import {
  ForegroundLayerContainer,
  ForegroundLayer,
  Title,
  BackgroundLayer,
  StageWrapper,
  Stage,
  BoxTopImage,
  StageSection,
} from '_components/common/StageSection'

import { Text } from '_components/common/Text'

import { ReactComponent as GiftImage } from './gift.svg'
import okkoImage from './okko.svg'
import smotreshkaImage from './smotreshka.svg'
import sberplayImage from './sberplay.svg'
import sberzvukImage from './sberzvuk.svg'
import wellbellImage from './wellbell.svg'

import s from './styles.module.scss'

const Gift: FC = ({ children }) => (
  <div className={s.gift}>
    <GiftImage />
    <span>{children || <>3&nbsp;месяца за&nbsp;1&nbsp;₽</>}</span>
  </div>
)

export const Services: VFC = () => {
  const boxTopImageRef = useRef<HTMLDivElement>(null)
  const clipPath = useRayClipPath(boxTopImageRef)

  return (
    <StageSection className={s.section} withCustomHeight={true}>
      <BackgroundLayer>
        <ScrollTrigger key={clipPath} start={'top 80%'} end={'40% 60%'} scrub={0.25}>
          <Tween
            ease={'power2.inOut'}
            from={{
              clipPath,
            }}
            to={{
              clipPath: 'inset(0px 0px 0px 0px)',
            }}
          >
            <StageWrapper>
              <Stage className={s.stage} />
            </StageWrapper>
          </Tween>
        </ScrollTrigger>
        <BoxTopImage className={s.boxTopImage} ref={boxTopImageRef} />
      </BackgroundLayer>
      <ForegroundLayerContainer className={s.foregroundLayer}>
        <ForegroundLayer>
          <Title>
            Кино, ТВ, музыка, игры&nbsp;&mdash; всё в&nbsp;одном<sup className={s.sup}>2</sup>
          </Title>
          <div className={s.services}>
            <ul className={s.servicesList}>
              <li className={clsx(s.serviceItem, s.okko)}>
                <div className={s.serviceItemContent}>
                  <img src={okkoImage} className={s.serviceLogo} alt={'Okko'} />
                  <Text withoutMargin={true} className={s.serviceDescription}>
                    80&nbsp;тысяч фильмов, мультфильмов и&nbsp;сериалов.
                  </Text>
                  <Gift />
                </div>
              </li>
              <li className={clsx(s.serviceItem, s.smotreshka)}>
                <div className={s.serviceItemContent}>
                  <img src={smotreshkaImage} className={s.serviceLogo} alt={'Smotreshka'} />
                  <Text withoutMargin={true} className={s.serviceDescription}>
                    Пакет ТВ-каналов &laquo;Silver&raquo;&nbsp;&mdash; более 185 телеканалов в&nbsp;цифровом качестве.
                  </Text>
                  <Gift />
                </div>
              </li>
              <li className={clsx(s.serviceItem, s.sberplay)}>
                <div className={s.serviceItemContent}>
                  <img src={sberplayImage} className={s.serviceLogo} alt={'Sberplay'} />
                  <Text withoutMargin={true} className={s.serviceDescription}>
                    War Thunder, Crossout, CRSED: F.O.A.D и&nbsp;другие PC-игры.
                  </Text>
                  <Gift>3&nbsp;мес.&nbsp;в&nbsp;подарок</Gift>
                </div>
              </li>
              <li className={clsx(s.serviceItem, s.sberzvuk)}>
                <div className={s.serviceItemContent}>
                  <img src={sberzvukImage} className={s.serviceLogo} alt={'Sberzvuk'} />
                  <Text withoutMargin={true} className={s.serviceDescription}>
                    50&nbsp;млн треков, аудиосказки, подкасты и&nbsp;радио.
                  </Text>
                  <Gift />
                </div>
              </li>
              <li className={clsx(s.serviceItem, s.wellbell)}>
                <div className={s.serviceItemContent}>
                  <img src={wellbellImage} className={s.serviceLogo} alt={'Wellbell'} />
                  <Text withoutMargin={true} className={s.serviceDescription}>
                    Видеотренировки и&nbsp;спорт в&nbsp;прямом эфире с&nbsp;опытными инструкторами.
                  </Text>
                  <Gift />
                </div>
              </li>
            </ul>

            <a href="https://sberdevices.ru/legal/promos" target="_blank" className={s.promoLegalLink} rel="noreferrer">
              Подробнее
            </a>
          </div>
        </ForegroundLayer>
      </ForegroundLayerContainer>

      <ScrollTrigger start={'top bottom'} end={'bottom top'} scrub={0.25}>
        <Tween
          from={{
            y: '20%',
          }}
          to={{
            y: '5%',
          }}
        >
          <div className={s.customers} />
        </Tween>
      </ScrollTrigger>
    </StageSection>
  )
}
