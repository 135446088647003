import { FC } from 'react'

import { ReactComponent as IconMIR } from './IconMIR.svg'
import { ReactComponent as IconVisa } from './IconVisa.svg'
import { ReactComponent as IconMastercard } from './IconMastercard.svg'
import { ReactComponent as IconJSB } from './IconJSB.svg'

import s from './styles.module.scss'

export const Footer: FC = () => (
  <footer className={s.root}>
    <div className={s.container}>
      <div className={s.menu}>
        <ul className={s.menuCol}>
          <li className={s.menuItem}>
            <a className={s.menuLink} href="https://salute.sber.ru/">
              Салют
            </a>
          </li>
          <li className={s.menuItem}>
            <a className={s.menuLink} href="https://sberdevices.ru/sberbox/">
              SberBox
            </a>
          </li>
          <li className={s.menuItem}>
            <a className={s.menuLink} href="https://sberdevices.ru/sberportal/">
              SberPortal
            </a>
          </li>
          <li className={s.menuItem}>
            <a className={s.menuLink} href="https://">
              Магазин
            </a>
          </li>
        </ul>
        <ul className={s.menuCol}>
          <li className={s.menuItem}>
            <a className={s.menuLink} href="#https://">
              SmartMarket
            </a>
          </li>
          <li className={s.menuItem}>
            <a className={s.menuLink} href="https://developers.sber.ru/">
              Разработчикам
            </a>
          </li>
          <li className={s.menuItem}>
            <a className={s.menuLink} href="https://sberdevices.ru/help/">
              Поддержка
            </a>
          </li>
          <li className={s.menuItem}>
            <a className={s.menuLink} href="https://sberdevices.ru/about/">
              О компании
            </a>
          </li>
          <li className={s.menuItem}>
            <a className={s.menuLink} href="https://">
              Вакансии
            </a>
          </li>
        </ul>
      </div>
      <div className={s.contacts}>
        <span className={s.phone}>900</span>
        <p className={s.contactsText}>Бесплатно с&nbsp;мобильного на&nbsp;территории&nbsp;РФ</p>
        <span className={s.phone}>+7&nbsp;495 500-55-50</span>
        <p className={s.contactsText}>Телефон для звонков из&nbsp;любой точки мира&nbsp;&mdash; по&nbsp;тарифам вашего оператора связи.</p>
        <p className={s.contactsText}>
          Общество с&nbsp;ограниченной ответственностью &quot;СберДевайсы&quot;.Москва, Кутузовский проспект, дом&nbsp;32, кабинет 6.24. ОГРН 1197746592394
        </p>
      </div>
      <ul className={s.policy}>
        <li className={s.policyItem}>
          <a className={s.policyLink} href="https://sberdevices.ru/legal/sale_terms">
            Условия продаж
          </a>
        </li>
        <li className={s.policyItem}>
          <a className={s.policyLink} href="https://sberdevices.ru/legal/privacy_policy/">
            Политика конфеденциальности
          </a>
        </li>
        <li className={s.policyItem}>
          <a className={s.policyLink} href="https://sberdevices.ru/legal/warranty_rules">
            Политика гарантийного обслуживания
          </a>
        </li>
      </ul>
      <div className={s.icons}>
        <IconMIR className={s.icon} />
        <IconVisa className={s.icon} />
        <IconMastercard className={s.icon} />
        <IconJSB className={s.icon} />
      </div>
    </div>
  </footer>
)
