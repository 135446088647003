import { FC } from 'react'
import { Tween, ScrollTrigger } from 'react-gsap'
import { useMobile } from '_hooks'

import iconPopcorn from './IconPopcorn.png'
import iconWeather from './IconWeather.png'
import iconLight from './IconLight.png'

import s from './styles.module.scss'

export const Dialogs: FC = () => {
  const { isMobile } = useMobile()

  return (
    <section className={s.root}>
      <div className={s.container}>
        <ul className={s.benefits}>
          <ScrollTrigger start={isMobile ? `+=5% 90%` : `+=10% 70%`} end={'bottom top'}>
            <li className={s.benefit}>
              <Tween from={{ y: '96px', opacity: 0 }} duration={0.9}>
                <img className={s.benefitIcon} src={iconPopcorn} alt="Popcorn" height="120" width="120" />
              </Tween>
              <Tween from={{ y: '128px', opacity: 0 }} duration={0.9}>
                <h3 className={s.benefitDialog}>
                  &mdash;&nbsp;Закажи
                  <br /> солёный попкорн
                </h3>
              </Tween>
              <Tween from={{ y: '128px', opacity: 0 }} duration={0.9}>
                <p className={s.benefitDescription}>Заказывайте снеки и&nbsp;другие продукты голосом в&nbsp;сервисе быстрой доставки &laquo;Самокат&raquo;</p>
              </Tween>
            </li>

            <li className={s.benefit}>
              <Tween from={{ y: '96px', opacity: 0 }} duration={0.9}>
                <img src={iconWeather} className={s.benefitIcon} alt="Weather" height="120" width="120" />
              </Tween>
              <Tween from={{ y: '128px', opacity: 0 }} duration={0.9}>
                <h3 className={s.benefitDialog}>
                  &mdash;&nbsp;Какая завтра
                  <br /> погода?
                </h3>
              </Tween>
              <Tween from={{ y: '128px', opacity: 0 }} duration={0.9}>
                <p className={s.benefitDescription}>Расскажет о&nbsp;погоде, пробках или последних новостях в&nbsp;мире</p>
              </Tween>
            </li>

            <li className={s.benefit}>
              <Tween from={{ y: '96px', opacity: 0 }} duration={0.9}>
                <img src={iconLight} className={s.benefitIcon} alt="SberLogo" height="120" width="120" />
              </Tween>
              <Tween from={{ y: '128px', opacity: 0 }} duration={0.9}>
                <h3 className={s.benefitDialog}>
                  &mdash;&nbsp;Включи
                  <br /> в&nbsp;спальне свет
                </h3>
              </Tween>
              <Tween from={{ y: '128px', opacity: 0 }} duration={0.9}>
                <p className={s.benefitDescription}>Управляйте умным домом&nbsp;&mdash; контролируйте работу умных ламп и&nbsp;розеток Sber удалённо</p>
              </Tween>
            </li>
          </ScrollTrigger>
        </ul>
      </div>
    </section>
  )
}
