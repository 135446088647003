import { VFC, Suspense, useCallback, useEffect, useLayoutEffect, useRef } from 'react'
import gsap from 'gsap'
import { Canvas } from '@react-three/fiber'
import { Environment, OrbitControls, Preload, useProgress, Stats } from '@react-three/drei'
import { ResizeObserver } from '@juggle/resize-observer'

import { IS_DEVELOPMENT } from '_consts'
import { useBackgroundContext, useWindowSize } from '_hooks'

import { Ray } from '_components/common/Ray'

import { Hand } from './components/Hand'
import { Scene } from './components/Scene'
import { StaticScene } from './components/StaticScene'

import { root as cameraBenefitsRoot } from '_components/sections/CameraBenefits/styles.module.scss'
import { root as introRoot } from '_components/sections/Intro/styles.module.scss'
import { root as talesRoot } from '_components/sections/Tales/styles.module.scss'
import { root as micBenefitsRoot } from '_components/sections/MicBenefits/styles.module.scss'
import { boxTopImage as servicesBoxTopImage } from '_components/sections/Services/styles.module.scss'
import { root as privacyBenefitsRoot } from '_components/sections/PrivacyBenefit/styles.module.scss'
import { boxTopImage as smartHomeBoxImage } from '_components/sections/SmartHome/styles.module.scss'
import { topContent as controlsTopContent } from '_components/sections/Controls/styles.module.scss'
import { root as dialogsRoot } from '_components/sections/Dialogs/styles.module.scss'

import type { TweenState, Pointer } from './types'
import { CUSTOM_EASING_STOP, PATHS } from './constants'

import s from './styles.module.scss'

const Events: VFC<{ onMouseMove: (pointer: Pointer) => void }> = ({ onMouseMove }) => {
  const { width: windowWidth, height: windowHeight } = useWindowSize()
  const halfWidth = windowWidth / 2
  const halfHeight = windowHeight / 2

  useEffect(() => {
    document.addEventListener('mousemove', event => {
      onMouseMove({
        x: (event.clientX - halfWidth) / halfWidth,
        y: (event.clientY - halfHeight) / halfHeight,
      })
    })
  })

  return null
}

const INITIAL_TWEEN_STATE: TweenState = {
  sceneRotationX: -Math.PI / 6.5,
  sceneRotationY: 0.75,
  sceneRotationZ: 0,
  //
  scenePositionX: 0,
  scenePositionY: 0,
  scenePositionZ: 0,
  //
  floatingImpact: 0.25,
}

export const Background: VFC = () => {
  const tween = useRef<TweenState>(INITIAL_TWEEN_STATE)
  const mouse = useRef<Pointer>({ x: 0, y: 0 })

  const [, setBackgroundContext] = useBackgroundContext()
  const loadingProgress = useProgress()
  const rayRef = useRef<HTMLDivElement>(null)
  const windowWidth = useWindowSize()

  useEffect(() => {
    requestAnimationFrame(() => {
      if (rayRef.current) {
        const { width } = rayRef.current.getBoundingClientRect()
        setBackgroundContext(state => ({ ...state, width }))
      }
    })
  }, [setBackgroundContext, windowWidth])

  useEffect(() => {
    setBackgroundContext(state => ({ ...state, loadingProgress: loadingProgress }))
  }, [setBackgroundContext, loadingProgress])

  const handleMouseMove = useCallback((pointer: Pointer) => {
    // mouse.current.x = pointer.x
    // mouse.current.y = pointer.y
  }, [])

  useLayoutEffect(() => {
    if (loadingProgress.progress < 100) {
      return
    }

    // prepare
    gsap.fromTo(
      tween.current,
      {
        scenePositionY: INITIAL_TWEEN_STATE.scenePositionY,
        sceneRotationX: INITIAL_TWEEN_STATE.sceneRotationX,
      },
      {
        duration: 1,
        scenePositionY: 6.2,
        sceneRotationX: 0.75,
        ease: 'none',
        scrollTrigger: {
          trigger: `.${introRoot}`,
          start: '90% 50%',
          end: '90% 50%',
          scrub: true,
          markers: false,
        },
      },
    )

    // Camera Benefits slide
    gsap.fromTo(
      tween.current,
      {
        scenePositionY: 6.2,
        immediateRender: false,
      },
      {
        duration: 1,
        scenePositionY: -6.2,
        ease: CUSTOM_EASING_STOP,
        scrollTrigger: {
          trigger: `.${cameraBenefitsRoot}`,
          start: '-10% 25%',
          end: '110% 100%',
          scrub: true,
          markers: false,
        },
      },
    )
    // Camera Benefits rotate
    gsap.fromTo(
      tween.current,
      {
        sceneRotationX: 0.75,
        immediateRender: false,
      },
      {
        duration: 1,
        sceneRotationX: -0.75,
        ease: 'none',
        scrollTrigger: {
          trigger: `.${cameraBenefitsRoot}`,
          start: '-10% 25%',
          end: '110% 100%',
          scrub: true,
          markers: false,
        },
      },
    )

    // prepare
    gsap.fromTo(
      tween.current,
      {
        scenePositionY: -6.2,
      },
      {
        duration: 1,
        scenePositionY: 6.2,
        ease: 'none',
        scrollTrigger: {
          trigger: `.${talesRoot}`,
          start: '50% 0%',
          end: '75% 0%',
          scrub: true,
          markers: false,
        },
      },
    )

    // Mic Benefits slide
    gsap.fromTo(
      tween.current,
      {
        scenePositionY: 6.2,
        immediateRender: false,
      },
      {
        duration: 1,
        scenePositionY: -6.2,
        ease: CUSTOM_EASING_STOP,
        scrollTrigger: {
          trigger: `.${micBenefitsRoot}`,
          start: '-10% 25%',
          end: '110% 100%',
          scrub: true,
          markers: false,
        },
      },
    )
    // Mic Benefits rotation
    gsap.fromTo(
      tween.current,
      {
        sceneRotationX: -0.75,
        immediateRender: false,
      },
      {
        duration: 1,
        sceneRotationX: 0.56,
        ease: 'none',
        scrollTrigger: {
          trigger: `.${micBenefitsRoot}`,
          start: '-10% 25%',
          end: '110% 100%',
          scrub: true,
          markers: false,
        },
      },
    )
    // Mic Benefits rotation
    gsap.fromTo(
      tween.current,
      {
        sceneRotationY: INITIAL_TWEEN_STATE.sceneRotationY,
        immediateRender: false,
      },
      {
        duration: 1,
        sceneRotationY: -0.6,
        ease: 'none',
        scrollTrigger: {
          trigger: `.${micBenefitsRoot}`,
          start: '-10% 25%',
          end: '100% 100%',
          scrub: true,
          markers: false,
        },
      },
    )

    // prepare
    gsap.fromTo(
      tween.current,
      {
        scenePositionY: -6.2,
      },
      {
        duration: 1,
        scenePositionY: 6.2,
        ease: 'none',
        scrollTrigger: {
          trigger: `.${servicesBoxTopImage}`,
          start: '100% 0%',
          end: '100% 50%',
          scrub: true,
          markers: false,
        },
      },
    )

    // Privacy Benefits slide
    gsap.fromTo(
      tween.current,
      {
        scenePositionY: 6.2,
      },
      {
        duration: 1,
        scenePositionY: -6.2,
        ease: CUSTOM_EASING_STOP,
        scrollTrigger: {
          trigger: `.${privacyBenefitsRoot}`,
          start: '-10% 25%',
          end: '110% 100%',
          scrub: true,
          markers: false,
        },
      },
    )
    // Privacy Benefits rotate
    gsap.fromTo(
      tween.current,
      {
        sceneRotationY: -0.6,
        sceneRotationX: 0.75,
      },
      {
        duration: 1,
        sceneRotationY: 0.75,
        sceneRotationX: 0.25,
        ease: 'power3.out',
        scrollTrigger: {
          trigger: `.${privacyBenefitsRoot}`,
          start: '-10% 25%',
          end: '110% 100%',
          scrub: true,
          markers: false,
        },
      },
    )

    // Controls prepare
    gsap.fromTo(
      tween.current,
      {
        scenePositionY: -6.2,
        sceneRotationY: 0.75,
        sceneRotationX: 0.25,
      },
      {
        duration: 1,
        scenePositionY: 0.95,
        sceneRotationY: 0.75,
        sceneRotationX: 0.25,
        ease: 'none',
        immediateRender: false,
        scrollTrigger: {
          trigger: `.${smartHomeBoxImage}`,
          start: '100%+=49px 0%',
          end: '100%+=51px 0%',
          scrub: true,
          markers: false,
        },
      },
    )

    // Controls floating impact
    gsap.fromTo(
      tween.current,
      {
        floatingImpact: INITIAL_TWEEN_STATE.floatingImpact,
      },
      {
        duration: 1,
        floatingImpact: 0,
        ease: 'power1.out',
        scrollTrigger: {
          trigger: `.${controlsTopContent}`,
          start: '-10% 50%',
          end: '100% 100%',
          scrub: true,
          markers: false,
        },
      },
    )

    // Controls slide
    gsap.fromTo(
      tween.current,
      {
        scenePositionY: 1.2,
        sceneRotationY: 0.75,
        sceneRotationX: 0.25,
      },
      {
        duration: 1,
        scenePositionY: -0.25,
        sceneRotationX: 0,
        sceneRotationY: 0,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: `.${controlsTopContent}`,
          start: '0% 50%',
          end: '100% 100%',
          scrub: true,
          markers: false,
        },
      },
    )

    // final
    gsap.fromTo(
      tween.current,
      {
        scenePositionY: 0,
      },
      {
        duration: 1,
        scenePositionY: -6.2,
        ease: 'none',
        scrollTrigger: {
          trigger: `.${dialogsRoot}`,
          start: '50% 50%',
          end: '50% 50%',
          scrub: true,
          markers: false,
        },
      },
    )
  }, [loadingProgress])

  return (
    <div className={s.root}>
      <Ray className={s.rayHolder} ref={rayRef} />
      <Canvas
        mode={'concurrent'}
        className={s.canvas}
        id={'canvas'}
        resize={{ polyfill: ResizeObserver }}
        dpr={[1, 2]}
        flat={false}
        linear={false}
        shadows={false}
        camera={{
          fov: 10,
          position: [0, 0, 12],
        }}
        gl={{
          antialias: false,
        }}
      >
        <Suspense fallback={null}>
          <Events onMouseMove={handleMouseMove} />
          <Environment files={PATHS.ENVIRONMENT} background={false} />
          <Environment preset="city" />
          <Scene tween={tween.current} mouse={mouse.current} />
          <Preload all={true} />
          {IS_DEVELOPMENT && <OrbitControls enableZoom={false} />}
          {IS_DEVELOPMENT && <Stats />}
        </Suspense>
      </Canvas>
      <StaticScene />
      <Hand />
    </div>
  )
}
