import { FC } from 'react'
import { Tween, ScrollTrigger } from 'react-gsap'
import { useMobile } from '_hooks'
import { BuyButton } from '_components/common/BuyButton'
import { ReactComponent as Logo } from '_images/sberboxtop.svg'
import tvBox from './tv.png'

import s from './styles.module.scss'

export const Buy: FC = () => {
  const { isMobile } = useMobile()

  return (
    <section className={s.root}>
      <ScrollTrigger start={`${isMobile ? '-50%' : '10%'} center`} end={`150% ${isMobile ? '70%' : 'center'}`} scrub={true}>
        <div className={s.spacerTop} />
        <Tween from={{ y: '-80%' }}>
          <div className={s.tvBox}>
            <img className={s.tvBoxImg} src={tvBox} alt="TV-Box" />
          </div>
        </Tween>
        <Tween from={{ y: '20%' }}>
          <div className={s.container}>
            <div className={s.logoContainer}>
              <Logo className={s.logo} />
            </div>
            <h2 className={s.price}> 14 990₽</h2>
            <BuyButton link="https://sberdevices.ru/tariffstop/" />
          </div>
        </Tween>
      </ScrollTrigger>
    </section>
  )
}
