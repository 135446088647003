import { FC } from 'react'
import { PlayState, Timeline, Tween } from 'react-gsap'

import { useLoaderContext } from '_hooks'

import { BuyButton } from '_components/common/BuyButton'
import { Ray } from '_components/common/Ray'
import { ReactComponent as Logo } from '_images/sberboxtop.svg'
import cesImage from './ces.png'

import s from './styles.module.scss'

export const Intro: FC = () => {
  const [{ done }] = useLoaderContext()

  return (
    <section className={s.root}>
      <div className={s.container}>
        <Timeline delay={2} ease={'power1.inOut'} playState={done ? PlayState.play : PlayState.pause}>
          <Tween from={{ opacity: 0, y: '-200px' }} duration={3}>
            <div className={s.backgroundLayer}>
              <Ray withGradient={true} className={s.rayHolder} />
              <div className={s.boxTopImage} />
            </div>
          </Tween>
          <Tween from={{ y: '-100px' }} duration={2} position={2}>
            <div className={s.foregroundLayer}>
              <Tween from={{ y: '-25px', opacity: 0 }} duration={2} position={2}>
                <p className={s.description}>ТВ-медиацентр с&nbsp;умной камерой</p>
                <Logo className={s.logo} />
              </Tween>
              <Tween from={{ opacity: 0 }} duration={2} position={2.5}>
                <p className={s.subtitle}>Посмотрите на домашние развлечения по-новому</p>
                <BuyButton link="https://sberdevices.ru/tariffstop/" />
              </Tween>
              <Tween from={{ opacity: 0 }} duration={2} position={2.75}>
                <div className={s.cesWrapper}>
                  <div className={s.cesPlatter} />
                  <img className={s.cesImage} src={cesImage} alt="CES INNOVATION AWARDS 2022 HONOREE" />
                  <p className={s.cesText}>Премия за инновации</p>
                </div>
              </Tween>
            </div>
          </Tween>
        </Timeline>
      </div>
    </section>
  )
}
