import { StrictMode } from 'react';
import { render } from 'react-dom';

import './scss/index.scss';
import { App } from '_components/App';

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);
