import { createContext, Dispatch, FC, SetStateAction, useState } from 'react'

type ContextType = {
  progress: number
  done: boolean
}
type LoaderContextType = [ContextType, Dispatch<SetStateAction<ContextType>>]

export const loaderContext = createContext<null | LoaderContextType>(null)

export const LoaderContextProvider: FC = ({ children }) => {
  const [contextValue, setContextValue] = useState<ContextType>({
    progress: 0,
    done: false,
  })

  const { Provider } = loaderContext

  return <Provider value={[contextValue, setContextValue]}>{children}</Provider>
}
