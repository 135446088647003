import React, { VFC, useRef, useEffect } from 'react'
import * as THREE from 'three'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import { useGLTF, useTexture } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import gsap from 'gsap'

import privacyBenefitsStyles from '_components/sections/PrivacyBenefit/styles.module.scss'
import smartHomeStyles from '_components/sections/SmartHome/styles.module.scss'

import { PATHS } from '../../constants'

const { lerp } = THREE.MathUtils

type GLTFResult = GLTF & {
  nodes: {
    side_grid_low: THREE.Mesh
    FrontDoor_Top: THREE.Mesh
    FrontDoor_Bottom: THREE.Mesh
    logo: THREE.Mesh
    SmallStuff: THREE.Mesh
    LampBorder: THREE.Mesh
    LampFrontStuff: THREE.Mesh
    MetalPartR_01: THREE.Mesh
    MetalPartR_02: THREE.Mesh
    MetalPartR_06: THREE.Mesh
    MetalPartR_04: THREE.Mesh
    MetalPartR_03: THREE.Mesh
    MetalPartL_01: THREE.Mesh
    MetalPartL_03: THREE.Mesh
    MetalPartR_05: THREE.Mesh
    MetalPartL_05: THREE.Mesh
    MetalPartL_02: THREE.Mesh
    MetalPartL_04: THREE.Mesh
    MetalPartL_06: THREE.Mesh
    Top: THREE.Mesh
    polySurface12: THREE.Mesh
    DoorSmall: THREE.Mesh
    polySurface14: THREE.Mesh
    BottomMoveCap1: THREE.Mesh
    polySurface13: THREE.Mesh
    polySurface9: THREE.Mesh
    polySurface10: THREE.Mesh
    pCylinder1: THREE.Mesh
    SmallDoor: THREE.Mesh
    TopButton: THREE.Mesh
    camera_lens: THREE.Mesh
    Glass: THREE.Mesh
  }
  materials: {
    grid: THREE.MeshStandardMaterial
    top: THREE.MeshStandardMaterial
    lens: THREE.MeshStandardMaterial
    ['glass.001']: THREE.MeshPhysicalMaterial
  }
}

type Props = JSX.IntrinsicElements['group']

type CurtainState = {
  bottomRotation: number
  topRotation: number
}

const CURTAIN_OPENED: CurtainState = {
  bottomRotation: -Math.PI / 2,
  topRotation: -Math.PI / 2,
}
const CURTAIN_CLOSED: CurtainState = {
  bottomRotation: -Math.PI / 1.23,
  topRotation: -Math.PI / 4,
}

export const Model: VFC<Props> = () => {
  const group = useRef<THREE.Group>()
  const doorTopRef = useRef<THREE.Mesh>()
  const doorBottomRef = useRef<THREE.Mesh>()
  const curtainRefState = useRef<CurtainState>(CURTAIN_OPENED)

  const { nodes, materials } = useGLTF(PATHS.MODEL) as GLTFResult
  const lensColorMap = useTexture(PATHS.LENS_TEXTURE)

  materials['glass.001'].color = new THREE.Color('rgb(255, 255, 255)')
  materials['glass.001'].emissive = new THREE.Color('rgb(0, 0, 0)')
  materials['glass.001'].emissiveIntensity = 0

  materials['glass.001'].clearcoat = 0
  materials['glass.001'].clearcoatRoughness = 1
  materials['glass.001'].envMapIntensity = 1
  // materials['glass.001'].reflectivity = 0.25
  // materials['glass.001'].refractionRatio = 0.598
  materials['glass.001'].transmission = 1
  materials['glass.001'].attenuationDistance = 0
  materials['glass.001'].attenuationColor = new THREE.Color('rgb(255, 255, 255)')
  materials['glass.001'].side = THREE.DoubleSide
  materials['glass.001'].depthFunc = 3
  materials['glass.001'].depthTest = true
  materials['glass.001'].depthWrite = true
  materials['glass.001'].colorWrite = true

  materials.lens.side = THREE.FrontSide
  materials.lens.side = THREE.DoubleSide
  materials.lens.color = new THREE.Color('rgb(255, 255, 255)')
  materials.lens.map = lensColorMap

  useEffect(() => {
    gsap.to(curtainRefState.current, {
      bottomRotation: CURTAIN_OPENED.bottomRotation,
      topRotation: CURTAIN_OPENED.topRotation,
      ease: 'none',
    })

    gsap.fromTo(
      curtainRefState.current,
      {
        bottomRotation: CURTAIN_OPENED.bottomRotation,
        topRotation: CURTAIN_OPENED.topRotation,
      },
      {
        duration: 1,
        bottomRotation: CURTAIN_CLOSED.bottomRotation,
        topRotation: CURTAIN_CLOSED.topRotation,
        ease: 'power4.out',
        scrollTrigger: {
          trigger: `.${privacyBenefitsStyles.spacerTop}`,
          endTrigger: `.${privacyBenefitsStyles.spacerBottom}`,
          start: '70% 50%',
          end: '25% 50%',
          scrub: true,
          markers: false,
        },
      },
    )
    gsap.fromTo(
      curtainRefState.current,
      {
        bottomRotation: CURTAIN_CLOSED.bottomRotation,
        topRotation: CURTAIN_CLOSED.topRotation,
      },
      {
        duration: 1,
        bottomRotation: CURTAIN_OPENED.bottomRotation,
        topRotation: CURTAIN_OPENED.topRotation,
        ease: 'none',
        scrollTrigger: {
          trigger: `.${smartHomeStyles.boxTopImage}`,
          start: '100% 50%',
          end: '100% 50%',
          scrub: true,
          markers: false,
        },
      },
    )
  }, [])

  useFrame(() => {
    if (doorTopRef.current && doorBottomRef.current) {
      doorTopRef.current.rotation.x = lerp(doorTopRef.current.rotation.x, curtainRefState.current.topRotation, 0.25)
      doorBottomRef.current.rotation.x = lerp(doorBottomRef.current.rotation.x, curtainRefState.current.bottomRotation, 0.25)
    }
  })

  return (
    <group ref={group} dispose={null} position={[0, 0, 0]}>
      <group name="Scene">
        <group userData={{ name: 'top.glb' }}>
          <mesh
            geometry={nodes.side_grid_low.geometry}
            material={nodes.side_grid_low.material}
            rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'side_grid_low' }}
          />
          <mesh
            geometry={nodes.FrontDoor_Top.geometry}
            material={nodes.FrontDoor_Top.material}
            position={[0, 0.03, 0.23]}
            // rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'FrontDoor_Top' }}
            ref={doorTopRef}
          />
          <mesh
            geometry={nodes.FrontDoor_Bottom.geometry}
            material={nodes.FrontDoor_Bottom.material}
            position={[0, 0.03, 0.23]}
            // rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'FrontDoor_Bottom' }}
            ref={doorBottomRef}
          />
          <mesh geometry={nodes.logo.geometry} material={nodes.logo.material} rotation={[-Math.PI / 2, 0, 0]} userData={{ name: 'logo' }} />
          <mesh geometry={nodes.SmallStuff.geometry} material={nodes.SmallStuff.material} rotation={[-Math.PI / 2, 0, 0]} userData={{ name: 'SmallStuff' }} />
          <mesh geometry={nodes.LampBorder.geometry} material={nodes.LampBorder.material} rotation={[-Math.PI / 2, 0, 0]} userData={{ name: 'LampBorder' }} />
          <mesh
            geometry={nodes.LampFrontStuff.geometry}
            material={nodes.LampFrontStuff.material}
            rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'LampFrontStuff' }}
          />
          <mesh
            geometry={nodes.MetalPartR_01.geometry}
            material={nodes.MetalPartR_01.material}
            rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'MetalPartR_01' }}
          />
          <mesh
            geometry={nodes.MetalPartR_02.geometry}
            material={nodes.MetalPartR_02.material}
            rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'MetalPartR_02' }}
          />
          <mesh
            geometry={nodes.MetalPartR_06.geometry}
            material={nodes.MetalPartR_06.material}
            rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'MetalPartR_06' }}
          />
          <mesh
            geometry={nodes.MetalPartR_04.geometry}
            material={nodes.MetalPartR_04.material}
            rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'MetalPartR_04' }}
          />
          <mesh
            geometry={nodes.MetalPartR_03.geometry}
            material={nodes.MetalPartR_03.material}
            rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'MetalPartR_03' }}
          />
          <mesh
            geometry={nodes.MetalPartL_01.geometry}
            material={nodes.MetalPartL_01.material}
            rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'MetalPartL_01' }}
          />
          <mesh
            geometry={nodes.MetalPartL_03.geometry}
            material={nodes.MetalPartL_03.material}
            rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'MetalPartL_03' }}
          />
          <mesh
            geometry={nodes.MetalPartR_05.geometry}
            material={nodes.MetalPartR_05.material}
            rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'MetalPartR_05' }}
          />
          <mesh
            geometry={nodes.MetalPartL_05.geometry}
            material={nodes.MetalPartL_05.material}
            rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'MetalPartL_05' }}
          />
          <mesh
            geometry={nodes.MetalPartL_02.geometry}
            material={nodes.MetalPartL_02.material}
            rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'MetalPartL_02' }}
          />
          <mesh
            geometry={nodes.MetalPartL_04.geometry}
            material={nodes.MetalPartL_04.material}
            rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'MetalPartL_04' }}
          />
          <mesh
            geometry={nodes.MetalPartL_06.geometry}
            material={nodes.MetalPartL_06.material}
            rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'MetalPartL_06' }}
          />
          <mesh geometry={nodes.Top.geometry} material={nodes.Top.material} rotation={[-Math.PI / 2, 0, 0]} userData={{ name: 'Top' }} />
          <mesh
            geometry={nodes.polySurface12.geometry}
            material={nodes.polySurface12.material}
            rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'polySurface12' }}
          />
          <mesh geometry={nodes.DoorSmall.geometry} material={nodes.DoorSmall.material} rotation={[-Math.PI / 2, 0, 0]} userData={{ name: 'DoorSmall' }} />
          <mesh
            geometry={nodes.polySurface14.geometry}
            material={nodes.polySurface14.material}
            rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'polySurface14' }}
          />
          <mesh
            geometry={nodes.BottomMoveCap1.geometry}
            material={nodes.BottomMoveCap1.material}
            rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'BottomMoveCap1' }}
          />
          <mesh
            geometry={nodes.polySurface13.geometry}
            material={nodes.polySurface13.material}
            rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'polySurface13' }}
          />
          <mesh
            geometry={nodes.polySurface9.geometry}
            material={nodes.polySurface9.material}
            rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'polySurface9' }}
          />
          <mesh
            geometry={nodes.polySurface10.geometry}
            material={nodes.polySurface10.material}
            rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'polySurface10' }}
          />
          <mesh geometry={nodes.pCylinder1.geometry} material={nodes.pCylinder1.material} rotation={[-Math.PI / 2, 0, 0]} userData={{ name: 'pCylinder1' }} />
          <mesh geometry={nodes.SmallDoor.geometry} material={nodes.SmallDoor.material} rotation={[-Math.PI / 2, 0, 0]} userData={{ name: 'SmallDoor' }} />
          <mesh geometry={nodes.TopButton.geometry} material={nodes.TopButton.material} rotation={[-Math.PI / 2, 0, 0]} userData={{ name: 'TopButton' }} />
          <mesh
            geometry={nodes.camera_lens.geometry}
            material={materials.lens}
            position={[0, 0, 0.01]}
            rotation={[-Math.PI / 2, 0, 0]}
            userData={{ name: 'camera_lens' }}
          />
        </group>
        <group userData={{ name: 'glass.glb' }}>
          <mesh geometry={nodes.Glass.geometry} material={materials['glass.001']} userData={{ name: 'Glass' }} />
        </group>
      </group>

      <spotLight
        name="SpotLight"
        intensity={0.2}
        angle={0.3}
        decay={2}
        distance={1.48}
        color="#973193"
        position={[1.93, 0.03, 0.48]}
        userData={{ name: 'SpotLight' }}
      >
        <group position={[0, 0, -1]} />
      </spotLight>
      <spotLight
        name="SpotLight_2"
        intensity={0.2}
        angle={0.3}
        decay={2}
        distance={1.48}
        color="#973193"
        position={[-1.95, 0.03, -0.06]}
        userData={{ name: 'SpotLight' }}
      >
        <group position={[0, 0, -1]} />
      </spotLight>
      <spotLight name="SpotLight_4" intensity={0.2} angle={Math.PI / 10} decay={2} color="#0414f6" position={[0, 4.47, 6.29]} userData={{ name: 'SpotLight' }}>
        <group position={[0, 0, -1]} />
      </spotLight>
    </group>
  )
}

useGLTF.preload(PATHS.MODEL)
