import { useMediaQuery } from 'react-responsive'

import { SASS_VARIABLES } from '_consts'

export const useMobile = () => {
  const isMobile = useMediaQuery({ query: `(max-width: ${SASS_VARIABLES.mobile})` })
  const isMobileMd = useMediaQuery({ query: `(max-width: ${SASS_VARIABLES.mobileMd})` })
  const isMobileSm = useMediaQuery({ query: `(max-width: ${SASS_VARIABLES.mobileSm})` })
  const isMobileMin = useMediaQuery({ query: `(max-width: ${SASS_VARIABLES.mobileMin})` })

  return {
    isMobile,
    isMobileMd,
    isMobileSm,
    isMobileMin,
  }
}
export const useDesktop = () => {
  const isDesktopMax = useMediaQuery({ query: `(max-width: ${SASS_VARIABLES.desktopMax})` })
  const isDesktopLg = useMediaQuery({ query: `(max-width: ${SASS_VARIABLES.desktopLg})` })
  const isDesktop = useMediaQuery({ query: `(max-width: ${SASS_VARIABLES.desktop})` })
  const isDesktopMd = useMediaQuery({ query: `(max-width: ${SASS_VARIABLES.desktopMd})` })
  const isDesktopSm = useMediaQuery({ query: `(max-width: ${SASS_VARIABLES.desktopSm})` })

  return {
    isDesktopMax,
    isDesktopLg,
    isDesktop,
    isDesktopMd,
    isDesktopSm,
  }
}
export const useTablet = () => {
  const isTablet = useMediaQuery({ query: `(max-width: ${SASS_VARIABLES.tablet})` })
  const isTabletMd = useMediaQuery({ query: `(max-width: ${SASS_VARIABLES.tabletMd})` })

  return {
    isTablet,
    isTabletMd,
  }
}
