import { useContext } from 'react'
import { loaderContext } from '_context'

export const useLoaderContext = () => {
  const context = useContext(loaderContext)
  if (!context) {
    throw new Error('useLoaderContext must be used within a LoaderContext')
  }

  return context
}
