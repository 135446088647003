import { FC, useEffect } from 'react'
import { Tween, ScrollTrigger } from 'react-gsap'
import gsap from 'gsap'

import { useMobile } from '_hooks'

import { ReactComponent as LeftHand } from './LeftHand.svg'
import { ReactComponent as CentralHand } from './CentralHand.svg'
import { ReactComponent as RightHand } from './RightHand.svg'

import { ReactComponent as LeftHandMobile } from './LeftHand-mobile.svg'
import { ReactComponent as CentralHandMobile } from './CentralHand-mobile.svg'
import { ReactComponent as RightHandMobile } from './RigthHand-mobile.svg'

import s from './styles.module.scss'
import smartHomeStyles from '_components/sections/SmartHome/styles.module.scss'

export const Controls: FC = () => {
  const { isMobile } = useMobile()

  useEffect(() => {
    gsap.fromTo(
      `.${s.spacerTop}`,
      {
        opacity: 1,
      },
      {
        duration: 1,
        opacity: 0,
        ease: 'none',
        scrollTrigger: {
          trigger: `.${smartHomeStyles.section}`,
          endTrigger: `.${smartHomeStyles.section}`,
          start: '50% 0%',
          end: '80% 0%',
          scrub: true,
          markers: false,
        },
      },
    )
  })

  return (
    <section className={s.root}>
      <div className={s.topContent}>
        <div className={s.spacerTop} />
        <div className={s.container}>
          <div className={s.introContainer}>
            <div className={s.introTitle}>
              Управляйте
              <br />
              как удобно
            </div>
          </div>
        </div>
        <div className={s.contentContainer}>
          <ScrollTrigger start={'+=100px 80%'} end={'bottom top'} scrub={true}>
            <div className={s.hands}>
              <Tween from={isMobile ? { y: '-150px', x: '-100px' } : { y: '-200px', x: '-150px' }}>
                <div className={s.leftHandContainer}>
                  {isMobile ? <LeftHandMobile className={s.handImg} aria-hidden="true" /> : <LeftHand className={s.handImg} aria-hidden="true" />}
                </div>
              </Tween>
              <Tween from={isMobile ? { y: '-150px' } : { y: '-250px' }}>
                <div className={s.centralHandContainer}>
                  {isMobile ? <CentralHandMobile className={s.handImg} aria-hidden="true" /> : <CentralHand className={s.handImg} aria-hidden="true" />}
                </div>
              </Tween>
              <Tween from={isMobile ? { y: '-150px', x: '100px' } : { y: '-200px', x: '150px' }}>
                <div className={s.rightHandContainer}>
                  {isMobile ? <RightHandMobile className={s.handImg} aria-hidden="true" /> : <RightHand className={s.handImg} aria-hidden="true" />}
                </div>
              </Tween>
            </div>
            <div className={s.title}>
              <p className={s.subtitleText}>Голосом</p>
              <p className={s.titleText}>&mdash;&nbsp;Салют!</p>
            </div>
          </ScrollTrigger>
        </div>
      </div>
    </section>
  )
}
