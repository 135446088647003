import { AnchorHTMLAttributes, forwardRef } from 'react'
import clsx from 'clsx'

import s from 'src/components/common/BuyButton/styles.module.scss'

type Props = {
  link: string
  className?: string
} & AnchorHTMLAttributes<HTMLAnchorElement>

export const BuyButton = forwardRef<HTMLAnchorElement, Props>(({ link, children, className, ...restProps }, ref) => (
  <a ref={ref} href={link} className={clsx(s.root, className)} {...restProps}>
    <span>{children || 'Купить'}</span>
  </a>
))
