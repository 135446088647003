import { ElementType, forwardRef, Ref, ReactElement } from 'react'
import clsx from 'clsx'

import s from './styles.module.scss'

type TagName = keyof JSX.IntrinsicElements
type Props<T extends TagName> = {
  className?: string
  tag?: ElementType
  withoutMargin?: boolean
  uppercase?: boolean
} & JSX.IntrinsicElements[T]

const TextComponent = <T extends TagName>(
  { className, tag: Tag = 'p', withoutMargin: withoutMarginProp, uppercase: uppercaseProp, ...restProps }: Props<T>,
  ref: Ref<JSX.IntrinsicElements[T]>,
) => (
  <Tag
    className={clsx(s.root, className, {
      [s.withoutMargin]: withoutMarginProp,
      [s.uppercase]: uppercaseProp,
    })}
    {...restProps}
    ref={ref}
  />
)

export const Text = forwardRef(TextComponent) as <T extends TagName>(props: Props<T> & { ref?: Ref<JSX.IntrinsicElements[T]> }) => ReactElement
