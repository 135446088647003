import { RefObject, useMemo } from 'react'
import { useWindowSize } from '_hooks/useWindowSize'
import { useBackgroundContext } from '_hooks/useBackgroundContext'

export const useRayClipPath = (boxTopImageRef: RefObject<HTMLDivElement>) => {
  const windowSize = useWindowSize()
  const [{ width }] = useBackgroundContext()

  const { topOffset, sideOffset } = useMemo(
    () => ({
      sideOffset: windowSize.width / 2 - width / 2,
      topOffset: boxTopImageRef.current ? boxTopImageRef.current.offsetTop + boxTopImageRef.current.clientHeight - 5 : 0,
    }),
    [boxTopImageRef, width, windowSize.width],
  )

  return `inset(${topOffset}px ${sideOffset}px 0px ${sideOffset}px)`
}
