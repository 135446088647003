import { FC } from 'react'
import { ScrollTrigger, Tween } from 'react-gsap'

import {
  ForegroundLayerContainer,
  ForegroundLayer,
  Title,
  Subtitle,
  BackgroundLayer,
  StageWrapper,
  Stage,
  BoxTopImage,
  StageSection,
  Overlay,
} from '_components/common/StageSection'
import { Text } from '_components/common/Text'
import { useMobile } from '_hooks'

import personTrainingImage from './person-training.png'
import personTrainingImageMobileMd from './person-training-mobile-md.png'
import arrow from './arrow.svg'
import arrowWithAngle from './arrow-with-angle.svg'
import arrowMobile from './arrow-mobile.svg'
import arrowWithAngleMobile from './arrow-with-angle-mobile.svg'

import s from './styles.module.scss'

export const Yoga: FC = () => {
  const { isMobileMd } = useMobile()

  return (
    <ScrollTrigger start={'50% top'} end={'150% top'} scrub={0.25}>
      <Tween to={{ y: '30%', ease: 'none' }}>
        <StageSection>
          <BackgroundLayer>
            <StageWrapper>
              <Stage className={s.stage} />
            </StageWrapper>
            <BoxTopImage />
            <ScrollTrigger start={'top bottom'} end={'bottom top'} scrub={0.25}>
              <Tween
                from={{
                  y: '5%',
                }}
                to={{
                  y: '-5%',
                }}
              >
                <div className={s.personTrainingContainer}>
                  {isMobileMd ? (
                    <img className={s.personTraining} src={personTrainingImageMobileMd} alt="" aria-hidden={true} />
                  ) : (
                    <img className={s.personTraining} src={personTrainingImage} alt="" aria-hidden={true} />
                  )}
                  <Tween from={{ y: '0%' }} to={{ y: '-5%' }} ease={'none'}>
                    <div className={s.personTrainingAnnotations}>
                      <img className={s.arrow} src={arrow} alt="" aria-hidden={true} />
                      <img className={s.arrowWithAngle} src={arrowWithAngle} alt="" aria-hidden={true} />

                      <img className={s.arrowMobile} src={arrowMobile} alt="" aria-hidden={true} />
                      <img className={s.arrowWithAngleMobile} src={arrowWithAngleMobile} alt="" aria-hidden={true} />

                      <Text withoutMargin={true} className={s.yogaRecommendationsNote}>
                        Даёт рекомендации
                      </Text>
                      <Text withoutMargin={true} className={s.yogaPositionsNote}>
                        Учитывает положение тела
                      </Text>
                    </div>
                  </Tween>
                </div>
              </Tween>
            </ScrollTrigger>
          </BackgroundLayer>
          <ForegroundLayerContainer>
            <ForegroundLayer>
              <Title className={s.title}>Фитнес и&nbsp;йога</Title>
              <Subtitle className={s.subtitle}>
                Улучшайте технику упражнений&nbsp;&mdash; виртуальный тренер анализирует положение тела и&nbsp;даёт рекомендации.
              </Subtitle>
            </ForegroundLayer>
          </ForegroundLayerContainer>
          <Tween to={{ opacity: 0.5, ease: 'power1.out' }}>
            <Overlay />
          </Tween>
        </StageSection>
      </Tween>
    </ScrollTrigger>
  )
}
