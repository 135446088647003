import { useContext } from 'react'
import { backgroundContext } from '_context'

export const useBackgroundContext = () => {
  const context = useContext(backgroundContext)
  if (!context) {
    throw new Error('useBackgroundContext must be used within a BackgroundContext')
  }

  return context
}
