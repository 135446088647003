import { FC } from 'react'
import { ScrollTrigger, Tween } from 'react-gsap'

import {
  ForegroundLayerContainer,
  ForegroundLayer,
  Title,
  Subtitle,
  BackgroundLayer,
  StageWrapper,
  Stage,
  BoxTopImage,
  StageSection,
  Overlay,
} from '_components/common/StageSection'
import { useMobile } from '_hooks'

import personDancingImage from './person-dancing.png'
import personDancingImageMobileMd from './person-dancing-mobile-md.png'

import s from './styles.module.scss'

export const Dancing: FC = () => {
  const { isMobileMd } = useMobile()

  return (
    <ScrollTrigger start={'50% top'} end={'150% top'} scrub={0.25}>
      <Tween to={{ y: '30%', ease: 'none' }}>
        <StageSection>
          <BackgroundLayer>
            <StageWrapper>
              <Stage className={s.stage} />
            </StageWrapper>
            <BoxTopImage />
            <ScrollTrigger start={'top bottom'} end={'bottom top'} scrub={0.25}>
              <Tween
                from={{
                  y: '30%',
                }}
                to={{
                  y: '20%',
                }}
              >
                <div className={s.personDancingContainer}>
                  {isMobileMd ? (
                    <img className={s.personDancing} src={personDancingImageMobileMd} alt="" aria-hidden={true} />
                  ) : (
                    <img className={s.personDancing} src={personDancingImage} alt="" aria-hidden={true} />
                  )}
                </div>
              </Tween>
            </ScrollTrigger>
          </BackgroundLayer>
          <ForegroundLayerContainer>
            <ForegroundLayer>
              <Title>Вечеринки хоть каждый день</Title>
              <Subtitle>
                Двигайтесь в&nbsp;такт музыке и&nbsp;ставьте новые рекорды с&nbsp;BeatDancer&nbsp;&mdash; эксклюзивной жестовой игрой от&nbsp;Сбера.
              </Subtitle>
            </ForegroundLayer>
          </ForegroundLayerContainer>
          <Tween to={{ opacity: 0.5, ease: 'power1.out' }}>
            <Overlay />
          </Tween>
        </StageSection>
      </Tween>
    </ScrollTrigger>
  )
}
