import { FC } from 'react'
import { useMobile } from '_hooks'

import { ReactComponent as IconAssistant } from './IconAssistant.svg'
import { ReactComponent as IconBluetooth } from './IconBluetooth.svg'
import { ReactComponent as IconTalk } from './IconVoice.svg'

import s from './styles.module.scss'

export const MicBenefits: FC = () => {
  const { isMobileSm } = useMobile()

  return (
    <section className={s.root}>
      <div className={s.spacerTop} />
      <div className={s.container}>
        <div className={s.title}>
          <p className={s.titleText}>Повторять не придётся</p>
        </div>
        <div className={s.subtitle}>
          <div className={s.subtitleText}>
            <p className={s.subtitleTextValue}>Матрица из 6 микрофонов</p>
          </div>
          {isMobileSm && <li className={s.benefit}>Управляйте SberBox Top голосом</li>}
        </div>
        <ul className={s.benefits}>
          <li className={s.benefit}>
            <IconAssistant className={s.benefitIcon} />
            Общайтесь с&nbsp;виртуальными ассистентами Салют
          </li>
          <li className={s.benefit}>
            {isMobileSm ? <IconTalk className={s.benefitIcon} /> : <IconBluetooth className={s.benefitIcon} />}
            Распознаёт команды даже издалека
          </li>
          {!isMobileSm && (
            <li className={s.benefit}>
              <IconTalk className={s.benefitIcon} />
              Управляйте SberBox Top голосом
            </li>
          )}
        </ul>
      </div>
      <div className={s.spacerBottom} />
    </section>
  )
}
