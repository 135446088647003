import { FC, useRef } from 'react'
import { ScrollTrigger, Tween } from 'react-gsap'

import { useMobile, useRayClipPath } from '_hooks'

import {
  ForegroundLayerContainer,
  ForegroundLayer,
  Title,
  Subtitle,
  BackgroundLayer,
  StageWrapper,
  Stage,
  BoxTopImage,
  StageSection,
  Overlay,
} from '_components/common/StageSection'

import frameImage from './frame.png'
import frameImageMobileMd from './frame-mobile-md.png'
import wolfImage from './wolf.png'

import s from './styles.module.scss'

export const Tales: FC = () => {
  const { isMobileMd } = useMobile()
  const boxTopImageRef = useRef<HTMLDivElement>(null)
  const clipPath = useRayClipPath(boxTopImageRef)

  return (
    <div className={s.root}>
      <ScrollTrigger key={clipPath} start={'50% top'} end={'150% top'} scrub={0.25}>
        <Tween to={{ y: '30%', ease: 'none' }}>
          <StageSection>
            <BackgroundLayer>
              <ScrollTrigger key={clipPath} start={'top 80%'} end={'40% 60%'} scrub={0.25}>
                <Tween
                  ease={'power2.inOut'}
                  from={{
                    clipPath,
                  }}
                  to={{
                    clipPath: 'inset(0px 0px 0px 0px)',
                  }}
                >
                  <StageWrapper>
                    <Stage className={s.stage} />
                  </StageWrapper>
                </Tween>
                <Tween from={{ scale: 1.75, y: '25%', autoAlpha: 0, ease: 'power2.inOut' }}>
                  {isMobileMd ? (
                    <img alt="" src={frameImageMobileMd} className={s.frame} aria-hidden={true} />
                  ) : (
                    <img alt="" src={frameImage} className={s.frame} aria-hidden={true} />
                  )}
                </Tween>
                <Tween from={{ scale: 1.75, y: '50%', autoAlpha: 0, ease: 'power2.in' }}>
                  <img alt="" src={wolfImage} className={s.wolf} aria-hidden={true} />
                </Tween>
              </ScrollTrigger>
              <BoxTopImage ref={boxTopImageRef} />
            </BackgroundLayer>
            <ForegroundLayerContainer>
              <ForegroundLayer>
                <Title>Любимые сказки в&nbsp;новом формате</Title>
                <Subtitle>Попробуйте приложения с&nbsp;дополненной реальностью. Магия, перевоплощения&nbsp;&mdash; дети в&nbsp;восторге.</Subtitle>
              </ForegroundLayer>
            </ForegroundLayerContainer>

            <Tween to={{ opacity: 0.5, ease: 'power1.out' }}>
              <Overlay />
            </Tween>
          </StageSection>
        </Tween>
      </ScrollTrigger>
    </div>
  )
}
