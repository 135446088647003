import { FC, useRef } from 'react'
import * as THREE from 'three'
import { useFrame } from '@react-three/fiber'

import { TweenState } from '../../types'

const { lerp } = THREE.MathUtils

export const Floating: FC<{ tween: TweenState }> = ({ tween, children }) => {
  const ref = useRef<THREE.Mesh>()

  useFrame(state => {
    if (!ref.current) {
      return
    }

    const t = state.clock.getElapsedTime()
    const impact = tween.floatingImpact / 5

    ref.current.rotation.x = lerp(ref.current.rotation.x, (impact * Math.cos(t / 2)) / 8, 0.1)
    ref.current.rotation.y = lerp(ref.current.rotation.y, (impact * Math.sin(t / 4)) / 4, 0.1)
    ref.current.rotation.z = lerp(ref.current.rotation.z, (impact * Math.sin(t / 4)) / 4, 0.1)
    ref.current.position.y = lerp(ref.current.position.y, (impact * (-2 + Math.sin(t))) / 3, 0.1)
  })

  return <group ref={ref}>{children}</group>
}
