import { FC, useRef } from 'react'
import { ScrollTrigger, Tween } from 'react-gsap'

import { useRayClipPath } from '_hooks'

import {
  ForegroundLayerContainer,
  ForegroundLayer,
  Title,
  BackgroundLayer,
  StageWrapper,
  Stage,
  BoxTopImage,
  StageSection,
} from '_components/common/StageSection'
import { Text } from '_components/common/Text'

import speaker from './speaker.svg'
import line from './line.svg'
import devices from './devices.svg'
import infraredDevices from './infrared-devices.svg'

import s from './styles.module.scss'

export const SmartHome: FC = () => {
  const boxTopImageRef = useRef<HTMLDivElement>(null)
  const clipPath = useRayClipPath(boxTopImageRef)

  return (
    <StageSection className={s.section} withCustomHeight={true}>
      <BackgroundLayer>
        <ScrollTrigger key={clipPath} start={'top 80%'} end={'40% 60%'} scrub={0.25}>
          <Tween
            ease={'power2.inOut'}
            from={{
              clipPath,
            }}
            to={{
              clipPath: 'inset(0px 0px 0px 0px)',
            }}
          >
            <StageWrapper>
              <Stage className={s.stage} />
            </StageWrapper>
          </Tween>
        </ScrollTrigger>
        <BoxTopImage className={s.boxTopImage} ref={boxTopImageRef} />
        <div className={s.annotation}>
          <img src={line} className={s.annotationLine} alt="" aria-hidden={true} />
          <img src={speaker} className={s.annotationSpeaker} alt="" aria-hidden={true} />
          <Text withoutMargin={true} className={s.annotationDescription}>
            SberBox Top работает даже при выключенном телевизоре&nbsp;&mdash; у&nbsp;него есть встроенный динамик
          </Text>
        </div>
      </BackgroundLayer>
      <ForegroundLayerContainer className={s.foregroundLayerContainer}>
        <ForegroundLayer className={s.foregroundLayer}>
          <Title className={s.title}>
            Центр
            <br />
            умного дома
          </Title>
          <ul className={s.prosList}>
            <li className={s.prosListItem}>
              <div className={s.prosListItemTitle}>
                <img aria-hidden={true} className={s.prosListItemImage} src={devices} alt="" />
              </div>
              <Text withoutMargin={true} className={s.prosListItemDescription}>
                Управляйте устройствами умного дома Sber дистанционно&nbsp;&mdash; настраивайте освещение, контролируйте электроприборы.
              </Text>
            </li>
            <li className={s.prosListItem}>
              <div className={s.prosListItemTitle}>
                <img aria-hidden={true} className={s.prosListItemImage} src={infraredDevices} alt="" />
              </div>
              <Text withoutMargin={true} className={s.prosListItemDescription}>
                Управляйте техникой с&nbsp;инфракрасным портом&nbsp;&mdash; меняйте громкость телевизора, включайте и&nbsp;выключайте питание.
              </Text>
            </li>
          </ul>
        </ForegroundLayer>
      </ForegroundLayerContainer>

      <ScrollTrigger start={'top bottom'} end={'bottom top'} scrub={0.25}>
        <Tween
          from={{
            y: '30%',
          }}
          to={{
            y: '15%',
          }}
        >
          <div className={s.lamp} />
        </Tween>
      </ScrollTrigger>
    </StageSection>
  )
}
