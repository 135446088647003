import { forwardRef } from 'react'
import clsx from 'clsx'

import s from './styles.module.scss'

export const Ray = forwardRef<HTMLDivElement, { className?: string; withGradient?: boolean }>(({ className, withGradient }, ref) => (
  <div className={clsx(s.root, className)} ref={ref}>
    <div className={clsx(s.layer, s.layerFirst)} />
    <div className={clsx(s.layer, s.layerSecond)} />
    <div className={clsx(s.layer, s.layerThird)} />
    {withGradient && <div className={s.gradient} />}
  </div>
))
