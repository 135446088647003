import { useLayoutEffect, useRef, useState } from 'react'
import gsap from 'gsap'

import { useBackgroundContext, useLoaderContext } from '_hooks'
import s from './styles.module.scss'

export const Loader = () => {
  const [isDone, setIsDone] = useState(false)
  const rootRef = useRef(null)
  const [, setLoaderContext] = useLoaderContext()
  const [{ loadingProgress }] = useBackgroundContext()
  let derivedProgress = 0

  if (loadingProgress?.progress) {
    derivedProgress = loadingProgress.progress
  }

  useLayoutEffect(() => {
    document.body.classList.add('loading')
  }, [])
  useLayoutEffect(() => {
    setLoaderContext({
      progress: derivedProgress,
      done: isDone,
    })
  }, [derivedProgress, isDone, setLoaderContext])

  useLayoutEffect(() => {
    if (derivedProgress >= 100 && !isDone) {
      gsap.to(rootRef.current, {
        opacity: 0,
        duration: 1,
        delay: 2,
        onStart: () => {
          document.body.classList.remove('loading')
        },
        onComplete: () => setIsDone(true),
      })
    }
  }, [derivedProgress, isDone])

  return isDone ? null : (
    <div className={s.root} ref={rootRef}>
      <div className={s.loader} />
    </div>
  )
}
