import { useEffect, useMemo, useRef, VFC } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import * as THREE from 'three'

import { useMobile, useTablet } from '_hooks'
import { DEVICE_IS_TOUCH_SUPPORT, IS_DEVELOPMENT } from '_consts'

import { DAMP_LAMBDA, DAMP_TIME, MODEL_MOVEMENT_DAMP_LAMBDA, MODEL_MOVEMENT_DAMP_TIME } from '../../constants'
import { Pointer, TweenState } from '../../types'

import { Floating } from '../Floating'
import { Model } from '../Model'

const { damp } = THREE.MathUtils

const MOUSE_IMPACT = DEVICE_IS_TOUCH_SUPPORT ? 0 : 0.1

export const Scene: VFC<{ tween: TweenState; mouse: Pointer }> = ({ tween, mouse }) => {
  const sceneRef = useRef<THREE.Mesh>(null)
  const modelRef = useRef<THREE.Group>(null)
  const modelWrapperRef = useRef<THREE.Mesh>(null)
  const plateRef = useRef<THREE.Mesh>(null)
  const { isMobile } = useMobile()

  useEffect(() => {
    if (sceneRef.current) {
      sceneRef.current.rotation.x = damp(sceneRef.current.rotation.x, tween.sceneRotationX, DAMP_LAMBDA, DAMP_TIME)
      sceneRef.current.rotation.y = damp(sceneRef.current.rotation.y, tween.sceneRotationY, DAMP_LAMBDA, DAMP_TIME)
      sceneRef.current.rotation.z = damp(sceneRef.current.rotation.z, tween.sceneRotationZ, DAMP_LAMBDA, DAMP_TIME)

      sceneRef.current.position.x = damp(sceneRef.current.position.x, tween.scenePositionX, DAMP_LAMBDA, DAMP_TIME)
      sceneRef.current.position.y = damp(sceneRef.current.position.y, tween.scenePositionY, DAMP_LAMBDA, DAMP_TIME)
      sceneRef.current.position.z = damp(sceneRef.current.position.z, tween.scenePositionZ, DAMP_LAMBDA, DAMP_TIME)
    }
  }, [mouse, tween])

  useFrame(() => {
    if (!sceneRef.current || !modelWrapperRef.current || !plateRef.current) {
      return
    }
    const { sceneRotationX, sceneRotationY, sceneRotationZ, scenePositionX, scenePositionY, scenePositionZ, floatingImpact } = tween

    sceneRef.current.position.x = damp(sceneRef.current.position.x, scenePositionX, MODEL_MOVEMENT_DAMP_LAMBDA, MODEL_MOVEMENT_DAMP_TIME)
    sceneRef.current.position.y = damp(sceneRef.current.position.y, scenePositionY, MODEL_MOVEMENT_DAMP_LAMBDA, MODEL_MOVEMENT_DAMP_TIME)
    sceneRef.current.position.z = damp(sceneRef.current.position.z, scenePositionZ, MODEL_MOVEMENT_DAMP_LAMBDA, MODEL_MOVEMENT_DAMP_TIME)

    sceneRef.current.rotation.x = damp(sceneRef.current.rotation.x, sceneRotationX, MODEL_MOVEMENT_DAMP_LAMBDA, MODEL_MOVEMENT_DAMP_TIME)
    sceneRef.current.rotation.y = damp(sceneRef.current.rotation.y, sceneRotationY, MODEL_MOVEMENT_DAMP_LAMBDA, MODEL_MOVEMENT_DAMP_TIME)
    sceneRef.current.rotation.z = damp(sceneRef.current.rotation.z, sceneRotationZ, MODEL_MOVEMENT_DAMP_LAMBDA, MODEL_MOVEMENT_DAMP_TIME)

    const { x, y } = isMobile ? { x: 0, y: 0 } : mouse
    const MOUSE_POSITION_IMPACT = floatingImpact * MOUSE_IMPACT
    const MOUSE_ROTATION_IMPACT = floatingImpact * MOUSE_IMPACT
    modelWrapperRef.current.position.x = damp(modelWrapperRef.current.position.x, x * MOUSE_POSITION_IMPACT, DAMP_LAMBDA, DAMP_TIME)
    modelWrapperRef.current.position.y = damp(modelWrapperRef.current.position.y, -y * MOUSE_POSITION_IMPACT, DAMP_LAMBDA, DAMP_TIME)

    modelWrapperRef.current.rotation.x = damp(modelWrapperRef.current.rotation.x, Math.sin(mouse.y) * MOUSE_ROTATION_IMPACT, DAMP_LAMBDA, DAMP_TIME)
    modelWrapperRef.current.rotation.y = damp(modelWrapperRef.current.rotation.y, Math.sin(mouse.x) * MOUSE_ROTATION_IMPACT, DAMP_LAMBDA, DAMP_TIME)
    modelWrapperRef.current.rotation.z = damp(modelWrapperRef.current.rotation.z, Math.sin(mouse.x) * MOUSE_ROTATION_IMPACT, DAMP_LAMBDA, DAMP_TIME)
  })

  const { viewport } = useThree()
  const tabletMatches = useTablet()
  const mobileMatches = useMobile()

  const modelScale = useMemo(() => {
    if (mobileMatches.isMobile) {
      return 0.65 * viewport.width
    }

    if (tabletMatches.isTabletMd) {
      return 0.72 * viewport.width
    }

    if (tabletMatches.isTablet) {
      return 0.5 * viewport.width
    }

    return 0.8
  }, [viewport, mobileMatches.isMobile, tabletMatches.isTablet, tabletMatches.isTabletMd])

  if (!tabletMatches.isTablet) {
    return null
  }

  return (
    <Floating tween={tween}>
      <mesh ref={sceneRef} scale={modelScale}>
        <mesh ref={modelWrapperRef}>
          <group ref={modelRef}>
            <Model />
          </group>
          {IS_DEVELOPMENT && modelRef.current && <boxHelper args={[modelRef.current, 0xffff00]} />}
          <mesh ref={plateRef} scale={1} position={[0, -10.223, -0.345]}>
            <planeGeometry args={[20.801, 20.5]} />
            <meshBasicMaterial color={0x000000} side={THREE.DoubleSide} />
          </mesh>
        </mesh>
      </mesh>
    </Floating>
  )
}
