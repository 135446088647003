import { FC, useEffect } from 'react'
import gsap from 'gsap'

import servicesStyles from '_components/sections/Services/styles.module.scss'

import { ReactComponent as IconMute } from './IconMute.svg'
import { ReactComponent as IconEyeClose } from './IconEyeClose.svg'

import s from './styles.module.scss'

export const PrivacyBenefits: FC = () => {
  useEffect(() => {
    gsap.fromTo(
      `.${s.spacerTop}`,
      {
        opacity: 1,
      },
      {
        duration: 1,
        opacity: 0,
        ease: 'none',
        scrollTrigger: {
          trigger: `.${servicesStyles.boxTopImage}`,
          endTrigger: `.${servicesStyles.customers}`,
          start: 'bottom+=100px 0%',
          end: '80% 0%',
          scrub: true,
        },
      },
    )
  }, [])

  return (
    <section className={s.root}>
      <div className={s.spacerTop} />
      <div className={s.container}>
        <div className={s.title}>
          <p className={s.titleText}>Уважает вашу приватность</p>
        </div>
        <ul className={s.benefits}>
          <li className={s.benefit}>
            <IconEyeClose className={s.benefitIcon} aria-hidden={true} />
            Когда камера не&nbsp;нужна, закройте её встроенной шторкой
          </li>
          <li className={s.benefit}>
            <IconMute className={s.benefitIcon} />
            Микрофоны легко отключить&nbsp;&mdash; нажмите кнопку, и&nbsp;они обесточатся
          </li>
        </ul>
      </div>
      <div className={s.spacerBottom} aria-hidden={true} />
    </section>
  )
}
