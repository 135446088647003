import { FC } from 'react'
import clsx from 'clsx'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { BackgroundContextProvider, LoaderContextProvider } from '_context'

import { Loader } from './Loader'

import { Header } from './Header'
import { Background } from './Background'
import { Intro } from './sections/Intro'
import { VideoCalls } from './sections/VideoCalls'
import { CameraBenefits } from './sections/CameraBenefits'
import { Tales } from './sections/Tales'
import { Yoga } from './sections/Yoga'
import { Dancing } from './sections/Dancing'
import { Services } from './sections/Services'
import { MicBenefits } from './sections/MicBenefits'
import { PrivacyBenefits } from './sections/PrivacyBenefit'
import { SmartHome } from './sections/SmartHome'
import { Controls } from './sections/Controls'
import { Dialogs } from './sections/Dialogs'
import { Buy } from './sections/Buy'
import { Footer } from './sections/Footer'

import s from './styles.module.scss'

gsap.registerPlugin(ScrollTrigger)
// @ts-ignore
gsap.core.globals('ScrollTrigger', ScrollTrigger)

export const App: FC = () => {
  return (
    <LoaderContextProvider>
      <BackgroundContextProvider>
        <>
          <div className={s.appHolder}>
            <Header />
            <main className={clsx(s.main)}>
              <Background />
              <div className={s.sectionsWrapper}>
                <Intro />
                <VideoCalls />
                <CameraBenefits />
                <Tales />
                <Yoga />
                <Dancing />
                <MicBenefits />
                <Services />
                <PrivacyBenefits />
                <SmartHome />
                <Controls />
                <Dialogs />
                <Buy />
                <Footer />
              </div>
            </main>
          </div>
          <Loader />
        </>
      </BackgroundContextProvider>
    </LoaderContextProvider>
  )
}
