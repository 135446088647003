import { VFC, forwardRef, useLayoutEffect, useRef } from 'react'
import clsx from 'clsx'
import gsap from 'gsap'

import { useTablet } from '_hooks'

import { root as cameraBenefitsRoot } from '_components/sections/CameraBenefits/styles.module.scss'
import { root as micBenefitsRoot } from '_components/sections/MicBenefits/styles.module.scss'
import { root as talesRoot } from '_components/sections/Tales/styles.module.scss'
import { root as introRoot } from '_components/sections/Intro/styles.module.scss'

import { boxTopImage as servicesBoxTopImage } from '_components/sections/Services/styles.module.scss'
import { root as privacyBenefitsRoot } from '_components/sections/PrivacyBenefit/styles.module.scss'
import { boxTopImage as smartHomeBoxImage } from '_components/sections/SmartHome/styles.module.scss'
import { topContent as controlsTopContent } from '_components/sections/Controls/styles.module.scss'
import { root as dialogsRoot } from '_components/sections/Dialogs/styles.module.scss'

import { CUSTOM_EASING_STOP } from '../../constants'

import cameraBenefits from './images/model-camera-benefits.png'
import microphoneBenefits from './images/model-microphone-benefits.png'
import controlsBenefits from './images/model-controls-benefits.png'
import privacyBenefits from './images/model-privacy-benefits.png'

import s from './styles.module.scss'

const ModelImage = forwardRef<
  HTMLImageElement,
  JSX.IntrinsicElements['img'] & {
    imageSubWrapperClassname?: string
    shadeClassname?: string
  }
>(({ className, imageSubWrapperClassname, shadeClassname, ...restProps }, ref) => (
  <div className={clsx(imageSubWrapperClassname, s.imageSubWrapper)} ref={ref}>
    <img className={clsx(s.image, className)} alt="Model" aria-hidden={true} {...restProps} />
    <div className={clsx(s.imageShade, shadeClassname)} />
  </div>
))

export const StaticScene: VFC<{ className?: string; imageWrapperClassName?: string }> = ({ className, imageWrapperClassName }) => {
  const tabletMatches = useTablet()
  const cameraBenefitsImageRef = useRef(null)
  const microphoneBenefitsImageRef = useRef(null)
  const controlsBenefitsImageRef = useRef(null)
  const privacyBenefitsImageRef = useRef(null)

  useLayoutEffect(() => {
    // Camera Benefits prepare
    gsap.fromTo(
      cameraBenefitsImageRef.current,
      {
        y: '500%',
        immediateRender: true,
      },
      {
        duration: 1,
        y: '-500%',
        ease: 'none',
        scrollTrigger: {
          trigger: `.${introRoot}`,
          start: '90% 50%',
          end: '90% 50%',
          scrub: true,
          markers: false,
        },
      },
    )
    // Camera Benefits slide
    gsap.fromTo(
      cameraBenefitsImageRef.current,
      {
        y: '-500%',
        immediateRender: true,
      },
      {
        duration: 1,
        y: '500%',
        ease: CUSTOM_EASING_STOP,
        scrollTrigger: {
          trigger: `.${cameraBenefitsRoot}`,
          start: '-10% 25%',
          end: '110% 100%',
          scrub: true,
          markers: false,
        },
      },
    )

    // Mic Benefits prepare
    gsap.fromTo(
      microphoneBenefitsImageRef.current,
      {
        y: '500%',
        immediateRender: true,
      },
      {
        duration: 1,
        y: '-500%',
        ease: 'none',
        scrollTrigger: {
          trigger: `.${talesRoot}`,
          start: '50% 0%',
          end: '75% 0%',
          scrub: true,
          markers: false,
        },
      },
    )

    // Mic Benefits slide
    gsap.fromTo(
      microphoneBenefitsImageRef.current,
      {
        y: '-500%',
        immediateRender: false,
      },
      {
        duration: 1,
        y: '500%',
        immediateRender: false,
        ease: CUSTOM_EASING_STOP,
        scrollTrigger: {
          trigger: `.${micBenefitsRoot}`,
          start: '-10% 25%',
          end: '110% 100%',
          scrub: true,
          markers: false,
        },
      },
    )

    // Privacy Benefits prepare
    gsap.fromTo(
      privacyBenefitsImageRef.current,
      {
        y: '500%',
        immediateRender: true,
      },
      {
        duration: 1,
        y: '-500%',
        ease: 'none',
        scrollTrigger: {
          trigger: `.${servicesBoxTopImage}`,
          start: '100% 0%',
          end: '100% 50%',
          scrub: true,
          markers: false,
        },
      },
    )

    // Privacy Benefits slide
    gsap.fromTo(
      privacyBenefitsImageRef.current,
      {
        y: '-500%',
        immediateRender: false,
      },
      {
        duration: 1,
        y: '500%',
        immediateRender: false,
        ease: CUSTOM_EASING_STOP,
        scrollTrigger: {
          trigger: `.${privacyBenefitsRoot}`,
          start: '-10% 25%',
          end: '110% 100%',
          scrub: true,
          markers: false,
        },
      },
    )

    // Controls prepare
    gsap.fromTo(
      controlsBenefitsImageRef.current,
      {
        y: '500%',
        immediateRender: true,
      },
      {
        duration: 1,
        y: '-100%',
        ease: 'none',
        immediateRender: true,
        scrollTrigger: {
          trigger: `.${smartHomeBoxImage}`,
          start: '100%+=49px 0%',
          end: '100%+=51px 0%',
          scrub: true,
          markers: false,
        },
      },
    )

    // Controls slide
    gsap.fromTo(
      controlsBenefitsImageRef.current,
      {
        y: '-100%',
        immediateRender: false,
      },
      {
        duration: 1,
        y: '50%',
        immediateRender: false,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: `.${controlsTopContent}`,
          start: '0% 50%',
          end: '100% 100%',
          scrub: true,
          markers: false,
        },
      },
    )

    // final
    gsap.fromTo(
      controlsBenefitsImageRef.current,
      {
        y: '50%',
        immediateRender: false,
      },
      {
        duration: 1,
        y: '500%',
        immediateRender: false,
        ease: 'none',
        scrollTrigger: {
          trigger: `.${dialogsRoot}`,
          start: '50% 50%',
          end: '50% 50%',
          scrub: true,
          markers: false,
        },
      },
    )
  }, [])

  // if () {
  //   return null
  // }

  return (
    <div className={clsx(s.root, className)} aria-hidden={true} hidden={tabletMatches.isTablet}>
      <div className={clsx(s.imageWrapper, imageWrapperClassName)}>
        <ModelImage ref={cameraBenefitsImageRef} src={cameraBenefits} alt="Camera benefits image" shadeClassname={s.cameraBenefitsImageShade} />
        <ModelImage
          ref={microphoneBenefitsImageRef}
          src={microphoneBenefits}
          alt="Microphone benefits image"
          className={s.microphoneBenefitsImage}
          shadeClassname={s.microphoneBenefitsImageShade}
        />
        <ModelImage
          ref={privacyBenefitsImageRef}
          src={privacyBenefits}
          alt="Microphone benefits image"
          className={s.privacyBenefitsImage}
          shadeClassname={s.privacyBenefitsImageShade}
          imageSubWrapperClassname={s.privacyBenefitsImageSubWrapper}
        />
        <ModelImage
          ref={controlsBenefitsImageRef}
          src={controlsBenefits}
          alt="Microphone benefits image"
          className={s.controlsBenefitsImage}
          shadeClassname={s.controlsBenefitsImageShade}
        />
      </div>
    </div>
  )
}
