import { FC } from 'react'
import { Tween, ScrollTrigger, PlayState } from 'react-gsap'

import { useLoaderContext } from '_hooks'

import personCard from './person-card.png'
import callSideControls from './call-side-controls.svg'
import userPic from './user-pic.png'
import callBottomControls from './call-bottom-controls.svg'

import s from './styles.module.scss'

export const VideoCalls: FC = () => {
  const [{ done }] = useLoaderContext()
  const initialTween = {
    from: {
      y: '68px',
    },
    duration: 2,
    delay: 4,
    playState: done ? PlayState.play : PlayState.pause,
  }

  return (
    <Tween from={{ opacity: 0 }} duration={2} delay={4} playState={done ? PlayState.play : PlayState.pause}>
      <section className={s.root}>
        <Tween {...initialTween}>
          <div className={s.backgroundLayer}>
            <img src={personCard} className={s.personCard} alt="" />
            <img src={callSideControls} className={s.callSideControls} alt="" />
            <img src={userPic} className={s.userPic} alt="" />
          </div>
        </Tween>
        <div className={s.foregroundLayer}>
          <div className={s.container}>
            <Tween {...initialTween}>
              <img src={callBottomControls} className={s.callBottomControls} alt="" />
            </Tween>
            <p className={s.title}>Видеозвонки на&nbsp;большом экране</p>
            <p className={s.subtitle}>
              Встречайтесь тет-а-тет или компанией.
              <br />
              Все в&nbsp;кадре, кто говорит — в&nbsp;фокусе.
            </p>
          </div>
        </div>

        <ScrollTrigger start={'top bottom'} end={'bottom top'} scrub={0.25}>
          <Tween
            from={{
              y: '15%',
            }}
            to={{
              y: '-10%',
            }}
          >
            <div className={s.customer} />
          </Tween>
        </ScrollTrigger>
      </section>
    </Tween>
  )
}
